/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Link, navigate } from "gatsby";
import { isEmpty } from "lodash";
import { AuthenticationState } from "../../context/AuthenticationContext";
import getApiData from "../../lib/getApiData";
import Button from "../htmlElements/button";
import LoadingIcon from "../loadingIcon";
import CopyToClibboardIcon from "../htmlElements/copyToClibboardIcon";

interface UserSubmissionsProps {
  userVerified: boolean;
}

interface CreateUserSubmissionsTableProps {
  userSubmissions: [];
}

interface CreateTableRowProps {
  submission: object;
}

interface CreateDoiReferenceProps extends CreateTableRowProps {
  bgColor: string;
}

function CreateDoiReference(props: CreateDoiReferenceProps) {
  const { submission, bgColor } = props;

  const { Data } = submission;

  return (
    <tr className={bgColor}>
      <td className="p-2 border border-gray" colSpan={5}>
        <div
          className="w-full py-2 pl-4 pr-10 relative"
          style={{ border: `1px dotted #aaa`, borderRadius: `8px` }}
        >
          {/* {Data.doiReference} */}
          <div
            className="markdownText"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: Data.doiReference,
            }}
          />
          <div className="absolute right-2 top-1">
            <CopyToClibboardIcon text={Data.doiReference} />
          </div>
        </div>
      </td>
    </tr>
  );
}

function CreateTableRow(props: CreateTableRowProps) {
  const { submission } = props;

  const { PK, Status, Data } = submission;

  const legacyState = Data.status || Data.state;

  const status = Status || legacyState || "-";

  const createdAt = Data.createdAt
    ? new Date(Data.createdAt).toUTCString()
    : "?";

  const lastModifiedAt = Data.lastModifiedAt
    ? new Date(Data.lastModifiedAt).toUTCString()
    : "?";

  const bgColor = status === "published" ? "bg-white" : "bg-gray-light";

  const icon = status === "published" ? "✅ " : "⌛ ";

  return (
    <React.Fragment key={PK}>
      <tr className={bgColor}>
        <td className="p-2 border border-gray">
          <Link to={`/details/${PK}`} className="underline">
            {PK}
          </Link>
        </td>
        <td className="p-2 border border-gray">
          {icon}
          {status.replace(/\b\w/g, (l) => l.toUpperCase())}
        </td>
        <td className="p-2 border border-gray">{createdAt}</td>
        <td className="p-2 border border-gray">{lastModifiedAt}</td>
        {/* <td className="p-2 border border-gray">[to do]</td> */}
        {/* <td className="p-2 border border-gray">[to do]</td> */}
        <td className="p-2 border border-gray text-right">
          <Button
            color="blue"
            innerContent="Edit material"
            paddingClasses="p-1"
            textSize="small"
            additionalClasses="text-white px-3"
            onClick={() => navigate(`/submit/${PK}/1`)}
          />
        </td>
      </tr>
      {Data.doiReference ? (
        <CreateDoiReference submission={submission} bgColor={bgColor} />
      ) : null}
      <tr>
        <td className="border-0" colSpan={5}>
          &nbsp;
        </td>
      </tr>
    </React.Fragment>
  );
}

function CreateUserSubmissionsTable(props: CreateUserSubmissionsTableProps) {
  const { userSubmissions } = props;

  const [sorting, setSorting] = useState("createdAt");
  const [tableList, setTableList] = useState<any>(
    <tr>
      <td>.</td>
    </tr>
  );

  /**
   * Create the list
   */
  useEffect(() => {
    if (userSubmissions) {
      const submissionsList = userSubmissions
        .sort(
          (a: never, b: never) =>
            new Date(b.Data[sorting]) - new Date(a.Data[sorting])
        )
        .map((submission: any) => {
          return <CreateTableRow submission={submission} key={submission.PK} />;
        });

      setTableList(submissionsList);
    }
  }, [userSubmissions, sorting]);

  return (
    <table className="table-fixed border-collapse border-xx border-gray-xx">
      <thead>
        <tr className="bg-gray-2">
          <th className="text-left p-2 border border-gray">Material ID</th>
          <th className="text-left p-2 border border-gray">Status</th>
          <th
            className="text-left p-2 border border-gray cursor-pointer"
            onClick={() => setSorting("createdAt")}
          >
            Created at
          </th>
          <th
            className="text-left p-2 border border-gray cursor-pointer"
            onClick={() => setSorting("lastModifiedAt")}
          >
            Last modified at
          </th>
          {/* <th className="text-left p-2 border border-gray">Title</th> */}
          {/* <th className="text-left p-2 border border-gray">Downloads</th> */}
          <th className="text-left p-2 border border-gray">&nbsp;</th>
        </tr>
      </thead>
      <tbody>{tableList}</tbody>
    </table>
  );
}

export default function UserSubmissions(props: UserSubmissionsProps) {
  const { userVerified } = props;

  const authenticationState = useContext(AuthenticationState) || {
    token: "",
    isAuthenticated: false,
  };

  const { token } = authenticationState;
  const [output, setOutput] = useState<any | string>(<LoadingIcon />);

  /**
   * Find all user submissions
   */
  useEffect(() => {
    (async () => {
      if (token && userVerified) {
        const submissions = await getApiData({
          endpoint: "user/submissions",
          method: "post",
          headers: {
            "X-Amz-Security-Token": token,
          },
        });

        // const submissions = { data: [] };

        if (isEmpty(submissions.data)) {
          setOutput("No submissions were found.");
        } else {
          const userSubmissionsTable = (
            <CreateUserSubmissionsTable userSubmissions={submissions.data} />
          );

          setOutput(userSubmissionsTable);
        }
      } else {
        setOutput(
          "Sorry, there seems to be a problem getting the submissions."
        );
      }
    })();
  }, []);

  return output;
}
